@import '../../styles/variables';

.cta-button-curaleaf {
  &[data-full-width='true'] {
    .btn {
      width: 100%;
    }
  }
  svg {
    margin-right: 8px;
  }
  a.btn {
    display: inline-block;
  }
  .btn {
    text-decoration: none;
    text-align: center;
    align-items: center;
    font-family: var(--font-bold);
    cursor: pointer;
    text-wrap: nowrap;
    &:hover {
      text-decoration: none;
    }
    &:focus {
      outline: none;
      box-shadow: var(--outline);
    }
    &:disabled {
      opacity: 0.65;
    }
  }
  .sm {
    border-radius: 4px;
    font-size: 12px;
    line-height: 18px;
    height: 33px;
    padding: 6px var(--spacing-unit);
  }
  .md {
    border: 2px solid var(--primary);
    border-radius: 8px;
    font-size: 14px;
    height: 44px;
    padding: 8px 24px;
  }
  .lg {
    border-radius: 16px;
    height: 56px;
    font-size: 16px;
    line-height: 24px;
    padding: 14px calc(var(--spacing-unit) * 2);
  }
  .cta-button-primary {
    color: var(--white);
    background-color: var(--primary);
    border: 2px solid var(--primary);
    &:hover,
    &:active,
    &:focus {
      opacity: 0.5;
    }
  }
  .cta-button-secondary {
    color: var(--primary);
    border: 2px solid var(--primary);
    background-color: var(--white);
    &:hover,
    &:focus,
    &:active {
      opacity: 0.5;
    }
  }
  .spinner-icon {
    cursor: default;
    .loading-spinner {
      background-color: transparent;
      margin: 0 auto;
      border: 2px solid var(--secondary-primary);
      border-right-color: var(--primary);
      border-radius: 50%;
      width: 20px;
      height: 20px;
      animation: spin 2s linear infinite;
    }
    &.cta-button-tertiary {
      .loading-spinner {
        border: 2px solid var(--primary);
        border-right-color: transparent;
      }
    }
    &:hover,
    &:active,
    &:focus {
      opacity: 0.5;
    }
  }
  .cta-button-disabled {
    cursor: default;
    border: 2px solid var(--primary);
    background-color: var(--primary);
    opacity: 0.8;
    color: var(--white);
    pointer-events: none;
  }

  @include media-breakpoint-down(sm) {
    &[data-full-width-mobile='true'] {
      .btn {
        width: 100%;
      }
    }
    &[data-full-width-mobile='false'] {
      .btn {
        width: unset;
      }
    }
  }
}
