@import '../../styles/variables';

.select-wrapper {
  position: relative;
  .p-dropdown {
    background-color: var(--secondary-light);
    border: 2px solid var(--primary);
    border-radius: 12px;
    height: 56px;
    .p-dropdown-label {
      min-width: 156px;
      font-family: var(--font-medium);
      font-size: 16px;
      line-height: 24px;
      padding: 14px 12px;
      color: var(--input-active);
    }
    .up {
      transition: all 0.3s;
      transform: rotate(180deg);
    }
  }
}
