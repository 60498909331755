.image-block {
  .video-container {
    position: relative;
    background-image: var(--bg-zzzz);
    display: flex;
    padding: 240px 50px;
    color: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    video {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
}

.cta-image-block {
  display: block;
  line-height: 0;
  @extend .image-block;
  position: relative;

  .cta-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
