@import '../../styles/variables';

.order-confirmation {
  .order-content {
    max-width: 660px;
    width: 100%;
    margin: 0 auto 50px auto;
    h2 {
      color: var(--primary);
    }
    p {
      margin-bottom: calc(var(--spacing-unit) * 2);
    }
  }
  .order-summary {
    max-width: 1050px;
    width: 100%;
    margin: 0 auto;
    .header {
      align-items: center;
      @include media-breakpoint-down(sm) {
        flex-direction: column;
        text-align: center;
      }
    }
    .order-tile-wrapper .product-list-tile {
      margin-bottom: 25px;
    }
    .inbv-info {
      width: 100%;
    }
    .pv-info {
      position: relative;
      @include media-breakpoint-down(md) {
        width: 100%;
        align-items: flex-end;
      }
      .variant-button {
        min-width: 88px;
        max-width: 105px;
        width: 100%;
        padding: var(--spacing-unit);
        border-radius: 4px;
        border: 1px solid var(--secondary-primary);
        &[data-has-variant='true'] {
          gap: 12px;
        }
      }
      .quantity-tag {
        z-index: 1;
        position: absolute;
        width: 28px;
        height: 28px;
        top: -8px;
        right: -8px;
        text-align: center;
        border-radius: 50%;
        background-color: var(--white);
        border: 1px solid var(--secondary-primary);
        padding-top: 3px;
      }
    }
    .order-total-wrapper > .order-total {
      background-color: var(--white);
      border: 1px solid var(--secondary-primary);
      width: 338px;
      padding: calc(var(--spacing-unit) * 2);
      .hairline {
        border-top: 1px solid var(--secondary-primary);
        margin: 10px 0;
      }
    }
  }
}
