@import '../../styles/variables';

.Breadcrumbs {
  padding: 24px calc(var(--spacing-unit) * 2);
  ul {
    flex-wrap: wrap;
    padding: 0;
    .link-container {
      .slash {
        padding: 0 8px;
      }
      &:last-child {
        overflow: hidden;
        .breadcrumb-name {
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .slash {
          display: none;
        }
      }
      &:hover > a {
        opacity: 0.5;
        text-decoration: underline;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    padding: var(--spacing-unit);
  }
}
