@import '../../../styles/variables';

.accordion-row {
  margin: var(--spacing-unit) 0;
  .store-list {
    margin: calc(var(--spacing-unit) * 2) 0;
  }
  .accordion-row-upper {
    &:hover {
      cursor: pointer;
    }
    .accordion-arrow {
      display: grid;
      place-items: center;
      svg {
        path {
          stroke: var(--pale-green);
          stroke-width: 4;
        }
      }
    }
  }
  .coming-soon {
    margin: calc(var(--spacing-unit) * 2) 0;
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    .accordion-row-upper {
      border-bottom: 1px solid var(--tertiary-primary);
      .accordion-arrow {
        border-radius: 50%;
        background-color: var(--tertiary-primary);
        aspect-ratio: 1;
        height: 50px;
        width: 50px;
        svg {
          path {
            stroke: var(--primary);
            stroke-width: 2;
          }
        }
      }
    }
  }
}
