.carousel {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  line-height: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
  .carousel-el {
    transition: 1s ease;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
  }
}
