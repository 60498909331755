.loyalty-status-bar {
  width: 100%;
  .status-bar-background {
    height: 18px;
    margin-bottom: calc(var(--spacing-unit) / 2);
    border-radius: 18px;
    .status-bar {
      border-top-left-radius: 18px;
      border-bottom-left-radius: 18px;
      height: 18px;
      &[data-full-bar='true'] {
        border-radius: 18px;
      }
    }
  }
}
