@import '../../styles/variables';

.text-block {
  &.container {
    padding: calc(var(--spacing-unit) * 2) var(--spacing-unit);
    @include media-breakpoint-up(md) {
      padding: calc(var(--spacing-unit) * 3) calc(var(--spacing-unit) * 2);
    }
  }
  .text-block-header {
    padding-bottom: var(--spacing-unit);
  }
  .text-block-content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var(--primary);
      margin-bottom: var(--spacing-unit);
    }
    p > a,
    li > a {
      text-decoration: underline;
    }
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-column-gap: var(--spacing-unit);
    grid-row-gap: var(--spacing-unit);
    p:last-child {
      margin-bottom: 0;
    }
    img {
      width: 100%;
    }
  }
  .button-group-center {
    display: flex;
    text-align: center;
  }
  .button-group-right {
    display: flex;
    text-align: right;
    @include media-breakpoint-down(sm) {
      display: block;
    }
  }
}
