@import '../../styles/variables';

.wide-banner-curaleaf {
  padding: 4px var(--spacing-unit);
  @include media-breakpoint-up(sm) {
    padding: 3px calc(var(--spacing-unit) * 2);
  }
  .link-container {
    width: 100%;
  }
  .close {
    width: 16.25px;
    height: 16.25px;
    > svg > path {
      fill: var(--white);
      &:hover {
        fill: var(--input-normal);
      }
    }
  }
}
