@import '../../../styles/variables';

.account-navigation {
  margin: 0 calc(var(--spacing-unit) * 2);
  padding: 0 0 calc(var(--spacing-unit) * 2) 0;
  @include media-breakpoint-down(sm) {
    margin: 0;
    padding: var(--spacing-unit);
    width: 100%;
    overflow-x: scroll;
    white-space: nowrap;
  }
  > button:first-child {
    margin-right: 12px;
  }
  > button:nth-child(2) {
    margin-right: 12px;
  }
  > button:nth-child(3) {
    margin-right: 12px;
  }
  > button {
    padding: 12px 18px;
    border: 1px solid var(--secondary-primary);
    cursor: pointer;
    color: var(--black);
    &:hover:enabled {
      color: var(--white);
      background-color: var(--primary);
    }
  }
  .active {
    cursor: initial;
    color: var(--primary);
    border-color: var(--primary);
  }
}
