@import '../../../styles/variables';

.article-header {
  padding: var(--spacing-unit);
  text-align: center;
  .heading {
    color: var(--primary);
  }
  .icon {
    width: 20px;
    height: auto;
    margin-right: 0.5rem;
    svg {
      width: 100%;
      height: auto;
      path {
        fill: var(--primary) !important; //overwrite inline svg style;
      }
    }
  }
  .item {
    line-height: 1;
    margin-top: 2px;
  }
  .author-reading-time {
    font-family: var(--font);
    margin-top: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary);
    padding-right: var(--spacing-unit);
    padding-left: var(--spacing-unit);
    text-align: left;
    .author {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1.5rem;
    }
    .reading-time {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 1.5rem;
    }
  }
  @include media-breakpoint-up(md) {
    padding: calc(var(--spacing-unit) * 2);
  }
}
