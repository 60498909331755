@import '../../../styles/variables';

.dropdown-curaleaf,
.text-input-curaleaf,
.password-input-curaleaf,
.account-multiselect-curaleaf {
  height: 77px;
  &.disclaimer {
    height: unset;
  }
}

.text-area-curaleaf textarea,
.text-input-curaleaf input,
.dropdown-curaleaf .p-dropdown,
.account-multiselect-curaleaf .multiselect {
  width: 100%;
}

.text-input-curaleaf input,
.dropdown-curaleaf .p-dropdown,
.password-input-curaleaf .p-password-input,
.account-multiselect-curaleaf .p-multiselect {
  height: 56px;
}

.account-multiselect-curaleaf,
.dropdown-curaleaf,
.text-input-curaleaf,
.password-input-curaleaf {
  .p-inputtext,
  .p-dropdown,
  .p-multiselect {
    border-radius: 12px;
    font-family: var(--font-medium);
    color: var(--primary);
    background-color: var(--secondary-light);
  }
  .p-dropdown:not(.p-disabled):hover,
  .p-dropdown:not(.p-disabled).p-focus,
  .p-inputtext:enabled:hover,
  .p-multiselect:not(.p-disabled):hover {
    border: 2px solid var(--secondary-light);
  }
  .p-dropdown:not(.p-disabled).p-focus,
  .p-inputtext:enabled:focus,
  .p-multiselect:not(.p-disabled).p-focus {
    box-shadow: var(--outline);
    border-color: var(--primary);
  }
  .p-float-label label {
    left: 12px;
    top: 45%;
  }
  .p-float-label.p-float-label:has(.p-inputwrapper-focus) label,
  .p-float-label:has(.p-inputwrapper-filled) label,
  .p-float-label:has(.p-inputwrapper-focus) label,
  .p-float-label:has(textarea.p-filled) label,
  .p-float-label:has(textarea:focus) label,
  .p-float-label .p-inputwrapper-filled ~ label,
  .p-float-label:has(input:focus) label,
  input.p-filled ~ label,
  input:focus ~ label {
    font-size: 12px;
    line-height: 18px;
    font-family: var(--font-medium);
    top: -14px;
    color: var(--primary);
    margin-bottom: 4px;
    left: 0;
    width: 100%;
  }

  &.form-error {
    .p-dropdown,
    .p-dropdown:not(.p-disabled):hover,
    .p-inputtext,
    .p-inputtext:enabled:hover {
      border: 2px solid var(--error-primary);
    }
    .p-error {
      position: absolute;
      bottom: -16px;
      color: var(--error-primary);
    }
  }
}

.dropdown-curaleaf .p-float-label:has(.p-inputwrapper-filled) .p-inputtext,
.account-multiselect-curaleaf .p-multiselect-label,
.password-input-curaleaf .p-inputtext {
  padding: 14px 12px;
}

// TEXT AREA
.text-area-curaleaf {
  textarea {
    border-radius: 12px;
    font-family: var(--font-medium);
    color: var(--primary);
    background-color: var(--secondary-light);
    padding: var(--spacing-unit) 12px;
    border: 2px solid var(--secondary-light);
  }
  .p-inputtext:enabled:focus {
    box-shadow: var(--outline);
    border-color: var(--primary);
  }
  .p-float-label:has(textarea.p-filled) label,
  .p-float-label:has(textarea:focus) label {
    font-size: 12px;
    line-height: 18px;
    font-family: var(--font-medium);
    top: -14px;
    color: var(--primary);
    margin-bottom: 4px;
    left: 0;
    width: 100%;
  }
}

// TEXT INPUT
.text-input-curaleaf {
  position: relative;
  margin-bottom: 18px;
  input {
    border: 2px solid var(--secondary-light);
    padding: var(--spacing-unit) 12px;
  }
  input.p-filled {
    border: 2px solid var(--primary);
  }
}

// DROPDOWN
.dropdown-curaleaf {
  .p-dropdown {
    border: 2px solid var(--secondary-light);
    .p-inputtext {
      font-size: 16px;
      line-height: 24px;
      padding: 14px 12px;
      color: var(--black);
    }
  }
  .p-dropdown.p-inputwrapper-filled {
    border: 2px solid var(--primary);
  }
}

.p-dropdown-panel {
  border: 2px solid var(--secondary-light);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  .p-dropdown-items {
    padding: var(--spacing-unit) 0;
    .p-dropdown-item {
      color: var(--primary);
      font-family: var(--font-medium);
    }
    .p-dropdown-item.p-highlight {
      background: var(--tertiary-light);
      margin: 0 6px;
      border-radius: 6px;
      font-family: var(--font-bold);
    }
    .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
      color: var(--input-hover);
      background: var(--white);
    }
  }
}

// CHECKBOX
.checkbox-shared {
  .p-checkbox,
  .p-checkbox-box {
    width: 16px;
    height: 16px;
  }
  .p-checkbox {
    margin-top: 3px;
    margin-right: 8px;
  }
  .p-multiselect-checkbox,
  .p-component {
    line-height: 0;
  }
  .p-checkbox .p-checkbox-box {
    border-radius: 2px;
  }
  .p-checkbox .p-checkbox-input {
    border: 2px solid var(--tertiary);
  }
  .p-checkbox.p-highlight .p-checkbox-box {
    border-color: var(--tertiary);
    background: var(--primary);
  }
  .p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
    border-color: var(--primary);
  }
  .p-checkbox:not(.p-disabled):has(.p-checkbox-input:focus-visible)
    .p-checkbox-box {
    box-shadow: var(--outline);
    border-color: var(--primary);
  }
}

.checkbox-container-curaleaf {
  @extend .checkbox-shared;
  label {
    color: var(--black);
  }

  &.form-error {
    .p-error {
      padding-left: 5px;
      color: var(--error-primary);
    }
    .p-checkbox .p-checkbox-box,
    .p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
      border: 2px solid var(--error-primary);
    }
  }
}

// MULTISELECT ACCOUNT
.account-multiselect-curaleaf {
  .p-multiselect {
    border: 2px solid var(--secondary-light);
    &.p-inputwrapper-filled {
      border: 2px solid var(--primary);
    }
  }
}

.p-multiselect-panel {
  @extend .checkbox-shared;
  .p-multiselect-item-group {
    font-family: var(--font-bold);
  }
  .p-multiselect-items {
    .p-multiselect-item {
      .p-checkbox {
        margin-top: 0;
      }
      padding: 10px var(--spacing-unit);
      display: flex;
      color: var(--primary);
    }
    .p-multiselect-item.p-highlight {
      color: var(--primary);
      background: var(--tertiary-light);
      margin: 0 6px;
      border-radius: 6px;
      font-family: var(--font-bold);
    }
    .p-multiselect-item:not(.p-highlight):not(.p-disabled).p-focus {
      background: var(--white);
      color: var(--primary);
      .p-checkbox .p-checkbox-box {
        border: 2px solid var(--primary);
      }
    }
  }
  .p-multiselect-header {
    display: none;
  }
}

// PASSWORD
.password-input-curaleaf {
  .p-inputtext {
    border: 2px solid var(--secondary-light);
  }
  .password-input,
  .p-icon-field,
  .p-password-input {
    width: 100%;
  }
}

// PASSWORD CHECKLIST
.password-checks {
  .check {
    height: 14px;
    .pi {
      font-size: 12px;
    }
  }
}
