@import '../../styles/variables';

.products-carousel-container {
  padding: calc(var(--spacing-unit) * 2) 0;
  @include media-breakpoint-down(sm) {
    padding: var(--spacing-unit) 0;
  }
  .carousel-container {
    padding-left: var(--spacing-unit);
    @include media-breakpoint-up(md) {
      padding-left: calc(var(--spacing-unit) * 2);
    }
    flex: 0 0 auto;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    .carousel-details {
      width: 100%;
    }
    .carousel-el {
      width: 24%;
      padding: 5px var(--spacing-unit) var(--spacing-unit) 0;
      @media (max-width: 1100px) {
        width: 32%;
      }
      @include media-breakpoint-down(sm) {
        width: 42%;
      }
      @media (max-width: 500px) {
        width: 47%;
      }
      &:last-child {
        padding-right: 0;
      }
    }
    .category-el {
      transition: ease 1000ms;
      flex-shrink: 0;
      max-width: 100%;
      min-width: 155px;
      width: 155px;
      padding-right: var(--spacing-unit);
      &:last-child {
        padding-right: 0;
      }
    }
  }
}

.specials-carousel {
  @extend .products-carousel-container;
  .carousel-container {
    .carousel-el {
      width: 24%;
      padding: 5px var(--spacing-unit) var(--spacing-unit) 0;
      @media (max-width: 1100px) {
        width: 32%;
      }
      @include media-breakpoint-down(sm) {
        width: 48%;
      }
      @media (max-width: 500px) {
        width: 85%;
      }
    }
  }
}

.store-info-carousel {
  padding: var(--spacing-unit);
  height: 100%;
  width: 100%;
  position: relative;
  border: 1px solid var(--tertiary-primary);
  border-radius: 16px;
  @include media-breakpoint-down(sm) {
    .cta-button-curaleaf {
      min-width: unset;
    }
  }
}

.storefront-banner-dots.thumbs {
  margin-top: var(--spacing-unit);
  display: flex;
  justify-content: center;
  .sm-dot {
    padding: 0;
    border: 1px solid #c9c9c9;
    width: 8px;
    height: 8px;
    background-color: #c9c9c9;
    border-color: #c9c9c9;
    border-radius: 100%;
    margin: 0 calc(var(--spacing-unit) / 3);
    &.active {
      border: 1px solid var(--primary);
      background-color: var(--primary);
      border-color: var(--primary);
    }
  }
}
