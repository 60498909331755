@import '../../styles/variables';

.alert {
  z-index: 998;
  display: grid;
  place-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: RGB(0 0 0 / 60%);
  overflow-x: hidden;
  transition: 0.5s;
  .alert-container {
    border-radius: var(--spacing-unit);
    position: relative;
    z-index: 9;
    overflow: hidden;
    max-width: 50rem;
    padding: calc(var(--spacing-unit) * 2) calc(var(--spacing-unit) * 3)
      calc(var(--spacing-unit) * 2) calc(var(--spacing-unit) * 3);
    @include media-breakpoint-up(md) {
      padding: calc(var(--spacing-unit) * 2) calc(var(--spacing-unit) * 5)
        calc(var(--spacing-unit) * 2) calc(var(--spacing-unit) * 5);
    }
    .close-icon {
      position: absolute;
      right: var(--spacing-unit);
      top: var(--spacing-unit);
    }
    .default-image {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
    }
    .text-container {
      position: relative;
    }
    .alert-buttons > section {
      width: auto !important;
    }
    @include media-breakpoint-down(sm) {
      .alert-title {
        line-height: 0.8;
      }
      .close-icon {
        right: var(--spacing-unit);
      }
    }
  }
}
