.social-sharing {
  flex-wrap: wrap;
  row-gap: var(--spacing-unit);
  button {
    .icon {
      height: auto;
      margin: 0 1rem;
      display: flex;
      align-items: center;
      svg {
        width: 32px;
        height: auto;
        path {
          fill: var(--primary);
        }
      }
      span {
        font-family: var(--font);
        margin-left: 0.5rem;
      }
    }
    &:hover {
      opacity: 0.5;
    }
  }
}
