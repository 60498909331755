.order-type-container {
  height: 40px;
  overflow: hidden;
  flex-direction: row-reverse;
  border: 1px solid var(--primary);
  .order-options {
    padding-inline: 10px;
    width: 100%;
    &:not(:first-child) {
      border-right: 1px solid var(--primary);
    }
  }
}

.order-type-container-loader {
  border: none;
}
