@import '../../styles/variables';

.cookie-banner-wrapper {
  position: fixed;
  z-index: 998;
  bottom: -1px;
  min-width: 375px;
  width: 100%;
  background-color: rgba(3, 30, 33, 0.96);
  border: 1px solid var(--primary);
  .cookie-banner {
    max-width: 1440px;
    width: 100%;
    padding: calc(var(--spacing-unit) * 2);
    color: var(--white);
    .text-wrapper {
      max-width: 915px;
    }

    position: relative;
    .close-cookie-banner {
      padding: 10px;
      position: absolute;
      right: var(--spacing-unit);
      top: 0;
      background-color: transparent;
      border: transparent;
      path {
        fill: var(--white);
      }
      &:hover {
        path {
          fill: var(--input-hover);
        }
      }
    }
    @include media-breakpoint-down(sm) {
      padding: calc(var(--spacing-unit) * 2) var(--spacing-unit)
        var(--spacing-unit);
    }
    @include media-breakpoint-down(xs) {
      .close-cookie-banner {
        right: 0;
      }
    }
  }
}
