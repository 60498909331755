@import '../../../styles/variables';
@import '../../../templates/Account/account-preferences.scss';

.account-sidebar {
  &.p-sidebar {
    max-width: 480px;
    width: 100% !important; //override primereact;
  }
  &.p-sidebar {
    .p-sidebar-header {
      display: none;
    }
    .p-sidebar-content .close-container {
      padding: calc(var(--spacing-unit) * 2) calc(var(--spacing-unit) * 2)
        var(--spacing-unit);
      @include media-breakpoint-down(sm) {
        padding: var(--spacing-unit);
      }
    }
  }
  .sidebar-header {
    padding: 0 calc(var(--spacing-unit) * 2);
    @include media-breakpoint-down(sm) {
      padding: 0 var(--spacing-unit);
    }
  }
  .sidebar-form {
    margin-top: calc(var(--spacing-unit) * 2);
    margin-bottom: calc(var(--spacing-unit) * 3);
  }
  .sidebar-form-padded {
    margin: calc(var(--spacing-unit) * 2) calc(var(--spacing-unit) * 2)
      calc(var(--spacing-unit) * 3);
    @include media-breakpoint-down(sm) {
      margin: calc(var(--spacing-unit) * 2) var(--spacing-unit)
        calc(var(--spacing-unit) * 3);
    }
  }
  .point-history {
    .history-tile {
      height: 60px;
      padding: 0 calc(var(--spacing-unit) * 2);
      @include media-breakpoint-down(sm) {
        padding: 0 var(--spacing-unit);
      }
      .row {
        line-height: 23px;
      }
      .points-tag {
        border-radius: 2px;
        padding: 2px 8px 0;
        height: 24px;
        margin-left: var(--spacing-unit);
      }
    }
  }
  .loyalty-perks {
    .sidebar-form {
      .member-status {
        padding: 0 calc(var(--spacing-unit) * 2);
        @include media-breakpoint-down(sm) {
          padding: 0 var(--spacing-unit);
        }
      }
      .p-accordion {
        .p-accordion-tab {
          margin-bottom: 0;
          &:last-of-type {
            .p-accordion-header .p-accordion-header-link {
              border-bottom: 1px solid var(--input-normal);
            }
            .p-accordion-header:not(.p-disabled).p-highlight
              .p-accordion-header-link {
              border-bottom: none;
            }
          }
          .p-accordion-header {
            &:not(.p-disabled).p-highlight .p-accordion-header-link {
              border-radius: 0;
              background: var(--white);
              color: var(--primary);
              border-color: var(--input-normal);
              padding: calc(var(--spacing-unit) * 2);
              @include media-breakpoint-down(sm) {
                padding: calc(var(--spacing-unit) * 2) var(--spacing-unit);
              }
            }
            .p-accordion-header-link {
              border-color: var(--input-normal);
              border-radius: 0;
              background: var(--white);
              color: var(--primary);
              border-bottom: none;
              border-left: none;
              border-right: none;
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-direction: row-reverse;
              padding: calc(var(--spacing-unit) * 2);
              @include media-breakpoint-down(sm) {
                padding: calc(var(--spacing-unit) * 2) var(--spacing-unit);
              }
            }
          }
          .p-accordion-content {
            color: var(--primary);
            border: none;
            border-radius: 0;
            padding: 0 calc(var(--spacing-unit) * 2) var(--spacing-unit);
            @include media-breakpoint-down(sm) {
              padding: 0 var(--spacing-unit) var(--spacing-unit);
            }
            .perk-list {
              list-style: none;
              margin: 0;
              padding: 0;
            }
          }
        }
      }
    }
  }
  .account-form {
    .cta-button-secondary,
    .ecom-button-secondary {
      margin-top: var(--spacing-unit);
      width: 100%;
    }
    .sidebar-form-padded {
      > section {
        margin-bottom: var(--spacing-unit);
      }
      .forgot-pw {
        border: none;
        background: transparent;
      }
    }
    .sidebar-header {
      .status-error {
        width: 100%;
        border: 1px solid var(--error-primary);
        border-left-width: 8px;
        border-radius: 4px;
        padding: 10px;
        color: var(--primary);
        margin-bottom: var(--spacing-unit);
        .pi-info-circle {
          margin-right: 5px;
          color: var(--error-primary);
        }
        .pi-times {
          color: var(--primary);
          font-size: 9px;
          margin-right: 5px;
          cursor: pointer;
        }
        .error-button {
          @extend .status-btn;
          border: 1px solid var(--error-primary);
          color: var(--error-primary);
        }
      }
      .status-success {
        @extend .status-error;
        border: 1px solid var(--success-primary);
        border-left-width: 8px;
        .pi-info-circle {
          color: var(--success-primary);
        }
        .success-button {
          @extend .status-btn;
          border: 1px solid var(--success-primary);
          color: var(--success-primary);
        }
      }
    }
  }
}
.status-btn {
  width: fit-content;
  height: 28px;
  display: flex;
  align-items: center;
  font-size: 14px;
  background-color: var(--white);
  border-radius: 8px;
  margin: 12px 25px 0;
  cursor: pointer;
  .pi-check {
    font-size: 14px;
    margin-right: 3px;
    &:disabled {
      cursor: default;
    }
  }
}
