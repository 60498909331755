@import '../../../styles/variables';

.article-card {
  text-align: left;
  border: 1px solid var(--secondary-primary);
  background-color: var(--white);
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: var(--spacing-unit);
  position: relative;
  .image {
    height: 14rem;
    width: 100%;
    overflow: hidden;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @include media-breakpoint-up(xxl) {
      height: 22rem;
    }
  }
  .card-body {
    padding: var(--spacing-unit);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 12rem;
    padding-bottom: 72px;
    @include media-breakpoint-up(xxl) {
      min-height: 16rem;
    }
    .card-title {
      margin-top: 10px;
      min-height: 0vw;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: var(--primary);
      &:hover {
        cursor: pointer;
      }
    }
  }
  .icon-link-curaleaf {
    position: absolute;
    bottom: var(--spacing-unit);
    right: var(--spacing-unit);
    text-align: right;
    svg {
      border-radius: 50%;
      transform: scale(0.5);
      g {
        stroke: var(--primary);
        fill: white;
      }
      path {
        stroke: var(--primary);
        fill: var(--primary);
      }
      &:hover {
        cursor: pointer;
        g {
          stroke: transparent;
          fill: var(--primary);
        }
        path {
          stroke: var(--white);
          fill: var(--white);
        }
      }
    }
  }
}
