@import '../../../styles/animations';

.CuraleafSpinner {
  position: relative;
  min-height: 100px;
  height: 100%;
  .spinner {
    animation: rotate 1.4s linear infinite;
    position: absolute;
    top: calc(50% - 37.5px);
    left: calc(50% - 37.5px);
    .path {
      stroke-width: 4;
      stroke: var(--secondary-primary);
      stroke-dasharray: 187;
      stroke-dashoffset: 0;
      transform-origin: center;
      animation: dash 2s ease-in-out infinite;
    }
  }
  > svg:last-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    > path {
      fill: var(--secondary-primary);
    }
  }
}
