@import '../../styles/variables';

.article-grid {
  .article-grid-container {
    text-align: center;
    .carousel-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-left: 0;
      padding-bottom: calc(var(--spacing-unit) * 2);
      overflow: hidden;
      flex: 0 0 auto;
      width: 100%;
      padding-right: 0 !important;
      .carousel-el {
        width: 85%;
        padding-right: var(--spacing-unit);
      }
      .carousel-el:last-child {
        padding-right: 0;
      }
    }
    .article-container {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: var(--spacing-unit);
      grid-column-gap: var(--spacing-unit);
      @include media-breakpoint-up(md) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
    .carousel-details {
      width: inherit;
      .card-container {
        width: 100% !important;
        height: 100%;
      }
    }
    .cta-button {
      margin-top: calc(var(--spacing-unit) * 2);
      width: auto;
    }
  }
  .grid-container {
    .article-container {
      padding: 0;
      flex-wrap: wrap;
    }
  }
}
