@import '../../../styles/variables';

.location-pref-finder {
  padding-top: var(--spacing-unit);
  .toggle-heading {
    padding-left: 0;
    text-align: center;
  }
  .toggles-container {
    padding: 0;
    .toggle {
      margin-bottom: var(--spacing-unit);
      display: flex;
      flex-direction: row;
      min-width: 20%;
      justify-content: space-between;
      .text-section .toggle-heading {
        margin-left: var(--spacing-unit);
        margin-bottom: 0;
      }
      .text-section > [data-test='delivery-tag'] {
        svg {
          transform: scale(1.3);
        }
      }
      svg {
        transform: scale(2);
      }
      .toggle-button {
        &:hover {
          cursor: pointer;
        }
        background-color: var(--tertiary-dark);
        width: 44px;
        height: 20px;
        position: relative;
        border-radius: 20px;
        &.active {
          background-color: var(--success-light);
        }
        .toggle-slider {
          background-color: var(--white);
          position: absolute;
          min-height: 60%;
          border-radius: 50%;
          aspect-ratio: 1;
          width: 16px;
          margin: 2px;
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    .toggle-heading {
      padding-left: 0;
    }
    .toggles-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .toggle {
        justify-content: normal;
        .toggle-button {
          margin-left: var(--spacing-unit);
        }
      }
    }
  }
}
